import React from "react";
import { useQuery} from '@apollo/client';
import Spinner from "../components/SpinnerComponent";

const Query = ({ children, query, id }) => {
  const { data, loading, error } = useQuery(query, {
    variables: { id: id }
  });
  if (loading) return <Spinner/>;
  if (error) return <p>Error: {JSON.stringify(error)}</p>;
  return children({ data });
};

export default Query;