import React from "react";
import * as ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";
import "../styles/quote.css"
import AOS from 'aos'
import 'aos/dist/aos.css';

function clickQuote() {
    
}

const Quote = ({ data }) => {
	AOS.init({
      duration : 2000
    })
    return (
        <div className="quote">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6" data-aos="fade-right" data-aos-duration="5000">
                        <ReactMarkdown source={data.text}/>
                    </div>
                    <div className="col-lg-6">
                        <Link to="/quote">
                            <button data-aos="fade-left" data-aos-duration="5000" onClick={clickQuote}>Demander votre devis</button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Quote;