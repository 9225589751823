import { gql } from "graphql-tag";

const PAGEVERTICAL_QUERY = gql`
query PagesVertical($id: ID!) {
  pagevertical(id: $id) {
    id
    pagesverticals {
      id
      title
      contents {
        ... on ComponentPageElementsHero {
        textcontent {
          title
          content
          align
        }
        image {
          url
        }
        direction
      }
      ... on ComponentPageElementsPageImage {
        img {
          url
        }
      }
      ... on ComponentPageElementsQuote {
        text
      }
      ... on ComponentPageElementsCustomers {
        Title
        customers {
          title
          image {
            url
          }
        }
      }
	  ... on ComponentPageElementsTransition {
		  transition 
		  {
			  url
		  }
	  }
      ... on ComponentCaptionSimpleText {
        textcontent {
          title
          content
          align
        }
      }
      ... on ComponentPageElementsSlider {
        textcontent {
          title
          content
          align
        }
        Slide {
          Picture {
            url
          }
          Caption
        }
      }
      ... on ComponentPageElementsService {
        textcontent {
          title
          content
          align
        }
        cards {
          title
          content
          image {
            url
          }
        }
        
      }
      ... on ComponentPageElementsService2{
        title
        ccontent
        textcontent {
          title
          content
          align
        }
        images{
          url
        }
      }
      ... on ComponentPageElementsTestimony {
        testimonies {
          title
          content
          image {
            url
          }
          
        }
        textcontent {
          title
          content
          align
        }
      }
	 ... on ComponentPageElementsTeam {
    textcontent {
      title
      content
      align
    }
        cardteam {
          title
          content
          image {
            url
          }
        }
	 }
	 ... on ComponentPageElementsMainLogo{
		 logo {
			 url 
		 }
     textcontent {
      title
      content
      align
    }
     
      }
	 ... on ComponentPageElementsElementservice{
     textcontent {
      title
      content
      align
    }
		 card {
			 title,
			 description
		 }
	 }

   ... on ComponentPageElementsServicejumbotron{
     textcontent {
      title
      content
      align
    }
		 jumbotron {
			 text,
			 svgpath
		 }
     }
     ... on ComponentPageElementsFormcontact{
      name,
      phone,
      message,
      mail
    }
    ... on ComponentPageElementsForm{
      name,
      phone,
      message,
      categorie,
      mail
    }
    }
    }
  }
}

`;



export {
    PAGEVERTICAL_QUERY,
}