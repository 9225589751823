import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import introspectionResult from "./fragmentTypes.json";
import getEnv from "./config";

const cache = new InMemoryCache(introspectionResult);
const link = new HttpLink({
  uri: `${getEnv("REACT_APP_BACKEND_URL")}/graphql`
});
const apolloClient = new ApolloClient({
  cache,
  link
});

export default apolloClient;
