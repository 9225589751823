import "../styles/slider.css";
import getEnv from '../utils/config';

const Slide = ({ data }) => {
    const imageUrl = data.Picture ? getEnv("REACT_APP_BACKEND_URL") + data.Picture.url: null;
    return (
        <div className="image-container">
            <img className="img-slide" height={"auto"} src={imageUrl} alt="slide"/> 
        </div>
   );
};

export default Slide;