import React from "react"
import ContentComponent from "./ContentComponent";

const SimpleText = ({ data }) => {
    return (
        <div className="service">
            <div className="container">
            <div className="content">
                    <div className="row">
                        <div className="col-lg-12">
                            {
                                data.textcontent ? (<ContentComponent data={data.textcontent}/>) : null
                            }
                        </div>
                    </div>
                </div>
			</div>
		</div>
    );
};


export default SimpleText;