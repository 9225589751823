import ReactMarkdown from "react-markdown";
import "../styles/card-team.css";
import AOS from 'aos'
import 'aos/dist/aos.css';
import getEnv from '../utils/config';

const Teammate = ({ card }) => {
  const imageUrl = card.image ? getEnv("REACT_APP_BACKEND_URL") + card.image.url: null;
  return (
    <div className="team-card" data-aos="zoom-in-up" data-aos-duration="3000">
      {
        imageUrl ? (
          <img className="team-image"  data-aos="flip-left" data-aos-duration="6000"  src={imageUrl} alt={card.image.alt} />
        ) : null
      }
      
      <div className="team-body">
          <h4 className="team-title">{card.title}</h4>
          <ReactMarkdown source={card.content} />
      </div>
    </div>
  );
};

export default Teammate;