import ReactMarkdown from "react-markdown";
import Card from "./CardComponent";
import "../styles/service.css";
import ContentComponent from "./ContentComponent";

//TODO- If IMAGE
//Passer le service.content dans du markdown parser
const Service = ({ data }) => {
    return (
        <div className="service">
            <div className="container">
            <div className="row">
                        <div className="col-lg-12">
                            {
                                data.textcontent ? (<ContentComponent data={data.textcontent}/>) : null
                            }
                        </div>
                    </div>
                <div className="row">
                    <div className="col-lg-12">
                        {
                            data.cards.map((card,i) => {
                                const key = "card-"+i;
                                return (<Card key={key} card={card} />);
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Service;