import React from "react"
import "../styles/service.css";
import ContentComponent from "./ContentComponent";
import ReactMarkdown from "react-markdown";
import getEnv from '../utils/config';

const Service2 = ({ data }) => {
    return (
        <div className="service">
            <div className="container">
            <div className="row">
                        <div className="col-lg-12">
                            {
                                data.textcontent ? (<ContentComponent data={data.textcontent}/>) : null
                            }
                        </div>
                    </div>
                <div className="row">
                        {
                            data.images.map((img,i) => {
								const imageUrl = img ? getEnv("REACT_APP_BACKEND_URL") + img.url: null;
								return (
											<div className="col-lg-4">
												<img className="service-image-container" src={imageUrl} alt="background" />
											</div>
                                        );
                            })
                        }
                </div>
            </div>
        </div>
    );
};


export default Service2;