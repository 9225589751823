import Query from "../components/QueryComponent";
import { PAGEVERTICAL_QUERY } from "../queries/PageQuery";

import Hero from "../components/HeroComponent";
import Service from "../components/ServiceComponent";
import Slider from "../components/SliderComponent";
import Testimonies from "../components/TestimoniesComponent";
import PageImage from "../components/PageImageComponent";
import Quote from "../components/QuoteComponent";
import Customers from "../components/CustomersComponent";
import SimpleText from "../components/SimpleTextComponent"
import Service2 from "../components/Service2Component";
import BigLogo from "../components/BigLogo";
import Transition from "../components/Transition";
import Team from "../components/TeamComponent";
import ElementService from "../components/ElementServiceComponent";
import ServiceJumbotron from "../components/ServiceJumbotron";
import FormMessage from "../components/FormMessage";
import FormDevis from "../components/FormDevis";

import ScrollToTopOnMount from "../components/utils/ScrollToTopOnMount";

const Components = {
    "ComponentPageElementsHero": Hero,
    "ComponentPageElementsService": Service,
    "ComponentPageElementsSlider": Slider,
    "ComponentPageElementsTestimony": Testimonies,
    "ComponentPageElementsQuote": Quote,
    "ComponentPageElementsPageImage": PageImage,
    "ComponentPageElementsCustomers": Customers,
    "ComponentCaptionSimpleText": SimpleText,
    "ComponentPageElementsService2" : Service2,
	"ComponentPageElementsMainLogo" : BigLogo,
	"ComponentPageElementsTransition" : Transition,
	"ComponentPageElementsTeam": Team,
    "ComponentPageElementsServicejumbotron" : ServiceJumbotron,
	"ComponentPageElementsElementservice" : ElementService,
    "ComponentPageElementsForm" : FormDevis,
    "ComponentPageElementsFormcontact" : FormMessage,
}
const PageContainer = (id) => {
    return (
        <>
            <ScrollToTopOnMount />
            <Query query={PAGEVERTICAL_QUERY} id={id.id}>
            
            {({ data: { pagevertical } }) => {
                return (
                    <div>
                        {
							
                            pagevertical.pagesverticals.map((page) => {
                                return page.contents.map((content, i) => {
                                    const ComponentName = Components[content.__typename];
                                    const key = `${ComponentName}-${i}`;
                                    return (<ComponentName key={key} data={content} />);
                                })
                            })
                        }
                    </div>
                );
            }}
            </Query>
        </>
    );
};

export default PageContainer;