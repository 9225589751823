import "../styles/content-component.css";
import ReactMarkdown from "react-markdown";
import gfm from 'remark-gfm'

const ContentComponent = ({ data }) => {
	const classnameForContent = `description text-${data.align}`;
	
	return (
		<div className="content-component container">
			<div className="row">
				<div className="content col-lg-12">
					{ data.title ? (<h1>{data.title}</h1>) : null }
					{ data.content ? (<ReactMarkdown className={classnameForContent} plugins={[gfm]} source={data.content} />) : null }
				</div>
			</div>
		</div>
	)
};

export default ContentComponent;