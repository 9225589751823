import "../styles/jumbotron.css";
import React from "react";
import 'aos/dist/aos.css';


const Jumbotron = ({ jumbotron }) => {    
      return (
        <div className="card" id="Jumbotron" data-aos="zoom-in-up" data-aos-duration="3000">
          <ImageJumbotron jumbotron={jumbotron}/>
          <TextJumbotron jumbotron={jumbotron}/>
        </div>
        );
  };
  
  const ImageJumbotron = ({jumbotron}) =>{
      return (
        <div className="red-circle" id="ImageJumbotron" data-aos="flip-left" data-aos-duration="6000">
          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 96 96" id="svg_3"><defs id="defs_4"><path id="path_5" d="M27 28.0005h41.523v41.522H27z" /></defs><g fill="none" fillRule="evenodd" id="g_6"><circle cx={48} cy={48} r={48} fill="#F22F46" id="circle_7" /><path fill="#FFF" d={jumbotron.svgpath} id="path_8" /></g></svg>
        </div>
        );
    };
  
  const TextJumbotron = ({jumbotron}) => {
    
      return <p id="TexteJumbotron">{jumbotron.text}</p>;
    };
  
  export default Jumbotron;