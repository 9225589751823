import React from "react";
import { Link } from "react-router-dom";

import logo from "../assets/logo192.png";
import "../styles/footer.css";

const Footer = () => {
    return (
        <div className="footer">
			<div className="navigation">
				<ul className="container inner-container">
					<li className="logo-box" style={{textAlign:"center", display:"grid"}}>
						<a className="nav-link" href="https://www.wmcs.fr"><img className="aspect-ratio" src={logo} alt="WM&CS" /></a>
						<small>© 2020 | <a className="black" href="https://www.wmcs.fr">WMCS</a></small>
					</li>
					<li className="menu-box" style={{textAlign:"center"}}>
						<p>
							Entreprise
						</p>
						<ul>
							<li>
								<Link to={`/customers`} className="nav-link">
									Clients
								</Link>
							</li>
							<li>
								<Link to={`/partenaire`} className="nav-link">
										Partenaires
								</Link>
							</li>
							<li>
								<Link to={`/contact`} className="nav-link">
									Contact
								</Link>
							</li>
						</ul>
					</li>
					<li className="menu-box" style={{textAlign:"center"}}>
						<p>
							Services
						</p>
						<ul>
							<li>
								<Link to={`/service/consulting`} className="nav-link">
									Consulting
								</Link>
							</li>
							<li>
								<Link to={`/service/developpement-de-webservice`} className="nav-link">
										Webservice
								</Link>
							</li>
							<li>
								<Link to={`/service/maintenance`} className="nav-link">
										Maintenance
								</Link>
							</li>
						</ul>
					</li>
					<li className="menu-box" style={{textAlign:"center"}}>
						<p>
							Ressources
						</p>
						<ul>
							<li>
								<Link to={`/`} className="nav-link">
										CGU 
								</Link>
							</li>
							<li>
								<Link to={`/`} className="nav-link">
									Politique de confidentialité
								</Link>
							</li>
							<li>
								<Link to={`/`} className="nav-link">
									Legal 
								</Link>
							</li>
						</ul>
					</li>
					<li className="social-box" style={{textAlign:"left"}}>
						<p>Adresse</p>
						<ul>
							<li className="text-white">WM&CS <br/> 10 rue de penthievre <br/> 75008 Paris</li>
							<span className="fa-stack"> 
								<a href="https://www.linkedin.com/company/web-media-cloud-services/about/">
									<i className="fas fa-circle fa-stack-2x"></i>
									<i className="fab fa-linkedin-in fa-stack-1x"></i>
								</a>
							</span>
						</ul>
					</li>
				</ul>
            </div>
        </div>
    );
  };
  
  export default Footer;