import ReactMarkdown from "react-markdown";
import Teammate from "./TeammateComponent";
import ContentComponent from "./ContentComponent";
import "../styles/team.css";

//TODO- If IMAGE
//Passer le service.content dans du markdown parser
const Team = ({ data }) => {
    return (
        <div className="team">
            <div className="container">
            <div className="content">
                    <div className="row">
                        <div className="col-lg-12">
                            {
                                data.textcontent ? (<ContentComponent data={data.textcontent}/>) : null
                            }
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        {
                            data.cardteam.map((card,i) => {
                                const key = "card-"+i;
                                return (<Teammate key={key} card={card} />);
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Team;