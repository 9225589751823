import Particles from 'react-tsparticles';	
import "../styles/header.css";
import ParticleAnimation from 'react-particle-animation'

const Header = () => {
    return (
        <header className="header">
            <ParticleAnimation 
                className="background-particles" 
                numParticles={200}
                noBackground={true}
                interactive={false}
                color={{
                    r: 255,
                    g: 255,
                    b: 255,
                    a: 255
                }}
                particleSpeed={3}
                particleRadius={1}
            />
        </header>
    );
  };
  
  export default Header;