import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';
import "../styles/form.css"
import getEnv from '../utils/config';

class FormMessage extends React.Component {
    constructor(props) {
      super(props);
      this.state = {captcha: false, buttondisable : true,mail : '', nom:'',message:'',phone:''};
      this.handleChangePhone = this.handleChangePhone.bind(this);
      this.handleChangeMail = this.handleChangeMail.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.handleChangeMessage = this.handleChangeMessage.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
    }
  
    handleChangeMail = (event) => {
      this.setState({mail: event.target.value});
    }

    handleChangeMessage(event) {
      this.setState({message: event.target.value});
    }

    handleChangePhone(event) {
      this.setState({phone: event.target.value});
    }

    handleChange(event) {
        this.setState({nom: event.target.value});
      }
  

    
    handleSubmit(event) {
        
        event.preventDefault();
        axios
        .post(`${getEnv("REACT_APP_BACKEND_URL")}/messages`, {
        mail: this.state.mail,
        name:this.state.nom,
        phone:this.state.phone,
        message:this.state.message
        })
        .then(response => {
            if(response.status == 200){
              alert("Message Envoyé")
            }
        });
        window.grecaptcha.reset();
        this.setState({buttondisable : true})
      }

      onChange =(value) => {
        axios
        .post(`${getEnv("REACT_APP_BACKEND_URL")}/captchas`, {
        text: value,
        })
        .then(response => {
        if(response.data){
        this.setState({buttondisable : false})
        }
        });

      }
  
    render() {
      
      return (
        <form className="form" onSubmit={this.handleSubmit}>
          <label>
            {this.props.data.name}
            <br/>
            <input type="text" value={this.state.nom} onChange={this.handleChange} />
          </label>
          <br/>
          <label>
            {this.props.data.mail}
            <br/>
            <input type="email" value={this.state.mail} onChange={this.handleChangeMail} />
          </label>
          <br/>
          <label>
            {this.props.data.phone}
            <br/>
            <input type="tel" value={this.state.phone} pattern="^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$" onChange={this.handleChangePhone}/>
          </label>
          <br/>
          <label>
            {this.props.data.message}
            <br/>
            <textarea className="input-message" rows="5" type="text" value={this.state.message} onChange={this.handleChangeMessage}/>
          </label>
          <br/>
          <ReCAPTCHA
            sitekey="6LedVCAdAAAAAFbVx2JnvPvUiEofJKLHa7oMXm2K"
            onChange={this.onChange}
            />
          <br/>
          <input type="submit" disabled={this.state.buttondisable} value="Envoyer" />
         
        </form>
        
      );
    }
  }

  export default FormMessage;