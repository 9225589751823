import React from "react";
import ReactDOM from "react-dom";
import App from "./containers/AppContainer";
import { BrowserRouter as Router } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import apolloClient from "./utils/apolloClient";

import "jquery";
import "bootstrap";

import "bootstrap/dist/css/bootstrap.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "./styles/index.css";

ReactDOM.render(
  <Router>
    <ApolloProvider client={apolloClient}>
      <App />
    </ApolloProvider>
  </Router>,
  document.getElementById("root")
);