import "../styles/transition.css";
import AOS from 'aos'
import 'aos/dist/aos.css';
import getEnv from '../utils/config';

const Transition = ({ data }) => {

	const imageUrl = data.transition ? getEnv("REACT_APP_BACKEND_URL") + data.transition.url: null;
  	return (
	<div className="separator" > 
		{
			imageUrl ? (
			<img  className="transition-image" data-aos="fade-up" data-aos-anchor-placement="center-bottom" src={imageUrl} alt={data.transition.alt} />
			) : null
		}
	</div>
)};

export default Transition;