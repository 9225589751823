import { useTransition, animated } from "react-spring"
import { Switch, Route, useLocation } from "react-router-dom";

import AOS from 'aos'

import Navbar from "../components/NavbarComponent";
import Header from "../components/HeaderComponent";
import Footer from "../components/FooterComponent";

import PageContainer from "./PageContainer";

function App() {
    const location = useLocation();
    const transitions = useTransition(location, location => location.pathname, {
        from: { opacity: 0, transform: 'translate3d(100%,0,0)', "marginLeft": "0%" },
        enter: { opacity: 1, transform: 'translate3d(0%,0,0)', "marginLeft": "0%" },
        leave: { opacity: 0, transform: 'translate3d(-50%,0,0)', "marginLeft": "-100%" },
    });
	AOS.init({
		duration : 2000
		})
    return (
		
        <div className="App" >
            <Navbar />
            <Header />
            <div className="page">
                {
                    transitions.map(({ item, props, key }) => (
                        <animated.div className="page-content" key={key} style={props}>
                            <Switch location={item}>
                                <Route exact path="/" >
                                    <PageContainer id={1} />
                                </Route>
                                <Route exact path="/contact" >
                                    <PageContainer id={2} />
                                </Route>
                                <Route exact path="/customers" >
                                    <PageContainer id={3} />
                                </Route>
                                <Route exact path="/partenaire" >
                                    <PageContainer id={4} />
                                </Route>
                                <Route exact path="/quote" >
                                    <PageContainer id={5} />
                                </Route>
                                <Route exact path="/service/creation-de-site-internet">
                                    <PageContainer id={6} />
                                </Route>
                                <Route exact path="/service/developpement-de-webservice" >
                                    <PageContainer id={7} />
                                </Route>
                                <Route exact path="/service/consulting" >
                                    <PageContainer id={8} />
                                </Route>
                                <Route exact path="/service/gestion-web" >
                                    <PageContainer id={9} />
                                </Route>
                                <Route exact path="/service/infrastructure-et-cloud" >
                                    <PageContainer id={10} />
                                </Route>
                                <Route exact path="/service/maintenance" >
                                    <PageContainer id={11} />
                                </Route>
                            </Switch>
                        </animated.div>
                    ))
                }
            </div>
            <Footer zIndex={2} />
        </div>
    );
}
export default App;