import React from "react";
import ReactMarkdown from "react-markdown";
import ContentComponent from "../components/ContentComponent";
import gfm from 'remark-gfm'
import AOS from 'aos'
import 'aos/dist/aos.css';
import "../styles/hero.css";
import getEnv from '../utils/config';

const Hero = ({ data }) => {
    AOS.init({
        duration : 2000
      })
    const direction = data.direction ? data.direction : "right";
    const imageUrl = data.image ? getEnv("REACT_APP_BACKEND_URL") + data.image.url: null;
    return (
        <div className="hero">
            <div className="hero-content">
                <div className="container">
                    
                        {
                            direction === "left" ? 
                                (
                                    <div className="row">
                                    <div className="col-lg-6" data-aos="fade-right">
                                        <div className="image-container">
                                            <img className="img-fluid" src={imageUrl} alt="background" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6" data-aos="fade-left">
                            <div className="contentt">
                            {
                                data.textcontent ? (<ContentComponent data={data.textcontent}/>) : null
                                
                            } 
                            </div>
                        </div>
                        </div>
                                    
                                ) : null
                        }
                        
                        {
                            direction === "right" ? 
                                (
                                    <div className="row">
                                        <div className="col-lg-6" data-aos="fade-right">
                            <div className="contentt">
                            {
                                data.textcontent ? (<ContentComponent data={data.textcontent}/>) : null
                            }     
                            </div>
                            </div>
                                    <div className="col-lg-6" data-aos="fade-left">
                                        <div className="image-container">
                                            <img className="img-fluid" src={imageUrl} alt="background" />
                                        </div>
                                    </div>
                                    </div>
                                ) : null
                        }
                    </div>
                </div>
            </div>
    );
};

export default Hero;