import React from "react";
import ReactMarkdown from "react-markdown";
import "../styles/testimony.css";
import "../styles/service.css";
import AOS from 'aos'
import 'aos/dist/aos.css';
import getEnv from '../utils/config';

const Testimony = ({ testimony }) => {
    const imageUrl = testimony.image ? getEnv("REACT_APP_BACKEND_URL") + testimony.image.url: null;
    return (
        <div className="testimony" data-aos="fade-up" >
            {
                imageUrl ? (
                    <img className="testimony-image" src={imageUrl} alt={testimony.image.alt} />
                ) : null
            }

            <div className="testimony-body">
                <h4 className="testimony-title">{testimony.title}</h4>
                <ReactMarkdown source={testimony.content} />
            </div>
        </div>
    );
};

export default Testimony;