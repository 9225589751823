import React from "react"
import "../styles/pageimage.css";
import getEnv from '../utils/config';

const PageImage = ({ data }) => {
    const imageUrl = data.img ? getEnv("REACT_APP_BACKEND_URL") + data.img.url: null;
    return (
        <div className="row">
            <div className="col-lg-12">
                <div className="img-page">
                    <img className="img-fluid" src={imageUrl} alt="pageimage" /> 
                </div>
            </div>
        </div>
    );
};

export default PageImage;