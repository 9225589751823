import React from "react";
import getEnv from '../utils/config';

const Customer = ({ customer }) => {
    const imageUrl = customer.image ? getEnv("REACT_APP_BACKEND_URL") + customer.image.url: null;
    return (
        <div className="card-customer">
            
            {
                imageUrl ? (
                    <img className="card-customer-image" src={imageUrl} alt={customer.image.alt} />
                ) : null
            }
            <div className="card-customer-body">
                <h4 className="card-customer-title">{customer.title}</h4>
            </div>
        </div>
       
    );
};

export default Customer;