import classNames from "classnames";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";


import logo from "../assets/logo192.png";
import "../styles/navbar.css";

const Navbar = () => {
    const [scrolled, setScrolled] = useState()
    const classes = classNames("navbar", "navbar-expand-lg", "navbar-custom", "fixed-top",
        {
            "top-nav-collapse": scrolled,
        });
    useEffect(_ => {
        const handleScroll = _ => {
            if (window.pageYOffset > 1) {
                setScrolled(true)
            } else {
                setScrolled(false)
            }
        }
        window.addEventListener('scroll', handleScroll)
        return _ => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    return (
        <nav className={classes}>
            <div className="container">
                <a className="navbar-brand logo-image" href="/"><img src={logo} alt="WM&CS" /></a>

                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarwmcs" aria-controls="navbarwmcs" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-awesome fas fa-bars"></span>
                    <span className="navbar-toggler-awesome fas fa-times"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarwmcs">
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <Link to={`/`} className="nav-link">
                                WMCS <span className="sr-only">(current)</span>
                            </Link>
                        </li>
                        <li className="nav-item-none">
                            <Link to={`/whyus`} className="nav-link">
                                Pourquoi nous ? <span className="sr-only">(current)</span>
                            </Link>
                        </li>

						<li className="nav-item dropdown" style={{paddingLeft:"0.01em"}}>
							<a className="nav-link dropdown-toggle page-scroll"  id="navbarDropdown" role="button" aria-haspopup="true" aria-expanded="false">
                                Services
                            </a>
							<div className="dropdown-menu" aria-labelledby="navbarDropdown">
								<Link to={`/service/creation-de-site-internet`} className="dropdown-item"><span className="item-text">Création site Internet</span></Link>
								<div className="dropdown-items-divide-hr"></div>
								<Link to={`/service/developpement-de-webservice`} className="dropdown-item"><span className="item-text">Développement de webservice</span></Link>
								<div className="dropdown-items-divide-hr"></div>
								<Link to={`/service/consulting`} className="dropdown-item"><span className="item-text">Consulting</span></Link>
								<div className="dropdown-items-divide-hr"></div>
								<Link to={`/service/gestion-web`} className="dropdown-item"><span className="item-text">Gestion Web</span></Link>
								<div className="dropdown-items-divide-hr"></div>
								<Link to={`/service/infrastructure-et-cloud`} className="dropdown-item"><span className="item-text">Infrastructure Hardware/Cloud</span></Link>
								<div className="dropdown-items-divide-hr"></div>
								<Link to={`/service/maintenance`} className="dropdown-item"><span className="item-text">Maintenance</span></Link>
							</div>
						</li>
                        <li className="nav-item">
                            <Link to={`/customers`} className="nav-link">
                                Clients
                            </Link>
                        </li>
                        <li className="nav-item-none">
                            <Link to={`/blog`} className="nav-link">
                                Blog
                            </Link>
                        </li>

                        <li className="nav-item-none">
                            <Link to={`/company`} className="nav-link">
                                Entreprise
                            </Link>
                        </li>
						<li className="nav-item">
                            <Link to={`/partenaire`} className="nav-link">
                                Nos partenaires
                            </Link>
                        </li>

                        <li className="nav-item-none">
                            <Link to={`/support`} className="nav-link">
                                Support
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;