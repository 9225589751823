import ContentComponent from "./ContentComponent";
import Jumbotron from "./Jumbotron";
import "../styles/servicejumbotron.css";


//TODO- If IMAGE
//Passer le service.content dans du markdown parser
const ServiceJumbotron = ({ data }) => {
    return (
        <div className="servicejumbotron">
            <div className="container">
                <div className="row">
                <div className="col-lg-12">
                            {
                                data.textcontent ? (<ContentComponent data={data.textcontent}/>) : null
                            }
                        </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        {
                            data.jumbotron.map((jumbotron,i) => {
                                const key = "jumbotron-"+i;
                                return (<Jumbotron key={key} jumbotron={jumbotron} />);
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServiceJumbotron;