import React from "react";
import ReactMarkdown from "react-markdown";
import Card from "./CardServiceComponent";	
import ContentComponent from "./ContentComponent";
import "../styles/elementservice.css";

const ElementService = ({ data }) => {
    return (
		<div className="element-service">
			<div className="container">
				<div className="row">
                        <div className="col-lg-12">
                            {
                                data.textcontent ? (<ContentComponent data={data.textcontent}/>) : null
                            }
                        </div>

					<div className="col-lg-12">
						{
							data.card.map((card,i) => {
								const key = "card-"+i;
								return (<Card key={key} card={card} />);
							})
						}
					</div>
				</div>
			</div>
		</div>
    );
};

export default ElementService;