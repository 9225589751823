import React from "react";
import "../styles/testimony.css"
import Testimony from "./TestimonyComponent";
import ContentComponent from "./ContentComponent";

const Testimonies = ({ data }) => {
    return (
        <div className="service">
			<div className="container">
			<div className="row">
                        <div className="col-lg-12">
                            {
                                data.textcontent ? (<ContentComponent data={data.textcontent}/>) : null
                            }
                        </div>
                    </div>
				<div className="row">
					<div className="col-lg-12">
						{
							data.testimonies.map((testimony, i) => {
								const key = "testimony-" + i;
								return (<Testimony key={key} testimony={testimony} />);
							})
						}
					</div>
				</div>
            </div>
        </div>
    );
};

export default Testimonies;