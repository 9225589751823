import "../styles/biglogo.css";
import ReactMarkdown from "react-markdown";
import ContentComponent from "./ContentComponent";
import getEnv from '../utils/config';

const BigLogo = ({ data }) => {
	const imageUrl = data.logo ? getEnv("REACT_APP_BACKEND_URL") + data.logo.url: null;
	return (
		<div className="biglogo container">
			<div className="row">
                        <div className="col-lg-12">
                            {
                                data.textcontent ? (<ContentComponent data={data.textcontent}/>) : null
                            }
                        </div>
			{
				imageUrl ? (
					<div className="col-lg-12">
						<img className="aspect-ratio" className="card-image" src={imageUrl} alt={data.logo.alt} />
					</div>
				) : null
			}
			</div>
		</div>
	)
};

export default BigLogo;