import "../styles/slider.css";
import Slide from "./SlideComponent"
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel'
import ContentComponent from "../components/ContentComponent";

const Slider = ({ data }) => {
    return (
        <div className="slider" >
            <div className="container">
                <div className="content">
                    <div className="row">
                        <div className="col-lg-12">
                            {
                                data.textcontent ? (<ContentComponent data={data.textcontent}/>) : null
                            }
                        </div>
                    </div>
                </div>
                <div className="slider-images">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <Carousel emulateTouch = { true} dynamicHeight={false} infiniteLoop={true} autoPlay={true} showStatus={false} useKeyboardArrows={true} showThumbs={false}>
                                {
                                    data.Slide.map((slide, i) => {
                                        const key = "slide-" + i;
                                        return (<Slide key={key} data={slide} />);
                                    })
                                }
                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default Slider;