import React from "react";
import "../styles/customer.css"
import Customer from "./CustomerComponent";

const Customers = ({ data }) => {
    return (
        <div className="customer-content">
            <div className="row">
                <div className="col-lg-12">
                    <h1>{data.Title}</h1>
                    {
                        data.customers.map((customer, i) => {
                            const key = "customer-" + i;
                            return (<Customer key={key} customer={customer} />);
                        })
                    }
                </div>
            </div>
        </div>
    );
};

export default Customers;