import ReactMarkdown from "react-markdown";
import "../styles/card.css";
import AOS from 'aos'
import 'aos/dist/aos.css';

const CardService = ({ card }) => {
  return (
    <div className="card" data-aos="zoom-in-up" data-aos-duration="3000">
      <div className="card-body">
          <h4 className="card-title">{card.title}</h4>
          <ReactMarkdown source={card.description} />
      </div>
    </div>
  );
};

export default CardService;